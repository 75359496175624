export default {
  type: "locations",
  name: null,
  excerpt: '',
  relationshipNames: ["organization"],
  organization: {
    type: "organizations",
    id: null,
  },
};
