<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="location.organization.id"
        :filterable="true"
        :showAll="false"
        :allowNone="false"
        @organizationChanged="
          (organizationId) => {
            location.organization.id = organizationId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="location.name"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <!-- Excerpt -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.EXCERPT')}`"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="location.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
    </div>

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          location.id
            ? $t("LOCATIONS.EDIT_LOCATION")
            : $t("LOCATIONS.ADD_LOCATION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["locationData", "formErrors", "loading"],

  data() {
    return {
      location: { ...this.locationData },
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      const locationData = cloneDeep(this.location);
      const me = this.$store.getters["profile/me"];
      if (!!me.organization?.id) {
        locationData.organization.id = me.organization.id;
      }
      this.$emit("locationSubmitted", locationData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    locationData(locationData) {
      if (locationData) {
        this.location = { ...this.location, ...cloneDeep(locationData) };
      }
    },
  },
};
</script>
